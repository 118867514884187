import React from "react";
import { Outlet, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "store/hooks";
import Navbar from "components/navbar";
import Loading from "components/loading";
import styles from "./application.module.scss";
import request from "request";

const ApplicationLayout = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);

  React.useEffect(() => {
    dispatch.application.FETCH_APPLICATION_BY_ID(id);
  }, [dispatch.application, id]);

  React.useEffect(() => {
    if (
      contextApplication.data?.scoring_log &&
      contextApplication.data?.pkb_ocr_log &&
      contextApplication.data?.ocr_log &&
      !contextApplication.data.cdn_log
    )
      request
        .post(`applications/${id}/cdn`)
        .then(() => dispatch.application.FETCH_APPLICATION_BY_ID(id));
  }, [contextApplication.data, dispatch, id]);

  return (
    <div className={styles.applicationLayout} id="application-layout">
      {(!contextApplication.data || contextApplication.pending) && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      {contextApplication.data && !contextApplication.pending && (
        <div className={styles.container}>
          <div className={styles.navbar}>
            <Navbar
              resultBlocked={contextApplication.data?.ocr_log === null}
            />
          </div>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationLayout;
