import { createModel } from "@rematch/core";
import global from "global";

export type UserT = {
  created_at: string;
  id: string;
  name: string;
  surname: string;
  email: string;
  role: string;
  is_active: boolean;
};

const initialValue: UserT = {
  created_at: "2019-08-24T14:15:22Z",
  id: "string",
  name: "string",
  surname: "string",
  email: "string",
  role: "ROOT",
  is_active: true,
};

const user = createModel()({
  state: initialValue as UserT,
  reducers: {
    INIT(_, payload: UserT) {
      return payload;
    },
    REMOVE() {
      return initialValue;
    },
  },
});

export default user;
