import React from "react";
import { AxiosError } from "axios";
import { Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import Button from "components/button";
import Loading from "components/loading";
import global from "global";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import styles from "./ocr.module.scss";
import ResultBox from "components/result-box";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: "bold",
    },
  ],
});

export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "3px 10px",
    fontWeight: "medium",
  },
  section: {
    marginBottom: "5px",
    marginTop: "5px",
  },
  heading: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
  },
  heading2: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "12px",
    marginBottom: "10px",
  },
  text2: {
    fontSize: "12px",
    marginTop: "10px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "12px",
  },
  th: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  result: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  bottom: {
    margin: "10px 0 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottom2: {
    marginTop: "40px",
  },
});

const OCR = () => {
  const [ocrPending, setOcrPending] = React.useState(false);
  const [filePKB, setFilePKB] = React.useState<File | null>();

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);

  const { id } = useParams();

  const handleRunOCR = async () => {
    setOcrPending(true);
    const fd = new FormData();
    if (filePKB) fd.append("pkb", filePKB);
    if (filePKB) fd.append("recognize", "pkb");
    if (filePKB) fd.append("recognize", "both");
    request({
      url: `/applications/${id}/ocr/pkb`,
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => res.data)
      .catch((e: AxiosError) => {
        console.log(e);
      })
      .finally(async () => {
        if (filePKB && contextApplication.data?.scoring_log)
          await request.post(`applications/${contextApplication.data.id}/cdn`);
        dispatch.application.FETCH_APPLICATION_BY_ID(id);
      });
  };

  // const handleFileChangeKASPI = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) setFileKASPI(event.target.files[0]);
  // };

  const handleFileChangePKB = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFilePKB(event.target.files[0]);
    }
  };

  if (!contextApplication.data) return <></>;

  //   const { first_name, last_name, email } =
  //     contextApplication.data.created_by ?? {};
  //   const {
  //     mean,
  //     id: kaspiId,
  //     created_at: kaspiCreatedAt,
  //     updated_at: kaspiUpdatedAt,
  //     starting_date: kaspiStartingDate,
  //     end_date: kaspiEndDate,
  //     is_approved: kaspiApproved,
  //     ...kaspi
  //   } = contextApplication.data.ocr_log ?? {};
  const {
    date_made,
    // id: pkbId,
    created_at: pkbCreatedAt,
    // updated_at: pkbUpdatedAt,
    // is_approved: pkbApproved,
    ...pkb
  } = contextApplication.data.pkb_ocr_log ?? {};

  return (
    <div className={styles.ocr}>
      {contextApplication.data?.ocr_log && (
        <div className={styles.fields}>
          {contextApplication.data?.ocr_log && (
            <div className={styles.creditDataBox}>
              {/* <div className={styles.title}>
                {transcript.bankType(contextApplication.data?.ocr_log.statement_type)}
              </div> */}
              <div className={styles.description}>
                <div>
                  OCR проведён экспертом:{" "}
                  {contextApplication.data.created_by.email}
                </div>
                <div>
                  Дата проведения OCR:{" "}
                  {new Date(contextApplication.data.created_at).toLocaleString()}
                </div>
              </div>
            </div>
          )}
          {contextApplication.data?.ocr_log && (
            <div className={styles.creditDataBox}>
              <div className={styles.title}>ПКБ</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>{"ID клиента"}</div>
                  <div className={styles.value}>
                    {contextApplication.data.user_id}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Количество активных кредитов"}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log?.active_loans}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Дата получения сведений кредитным бюро"}
                  </div>
                  <div className={styles.value}>
                    {new Date(
                      contextApplication.data.ocr_log.date_made
                    ).toLocaleDateString()}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>{"Клиент признан банкротом"}</div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.bankrupt ? "Есть" : "Нет"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>{"КДН"}</div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.kdn}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>{"КПД"}</div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.kpd}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Наличие доступа к своему кредитному отчёту"}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.no_access ? "Есть" : "Нет"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Непогашенная/оставшаяся сумма"}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.outstanding_amount}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Просроченные платежи за последние 60 дней"}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.overdue_60_days
                      ? "Есть"
                      : "Нет"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>{"Просроченная сумма"}</div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.overdue_amount}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {"Вовлечения в игорный бизнес"}
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.player ? "Есть" : "Нет"}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    {
                      "Количество произведенных участниками ПКБ запросов за последние 30 дней"
                    }
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.ocr_log.requests_30_days}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!contextApplication.data?.ocr_log && !ocrPending && (
        <div className={styles.inputBox}>
          <label className={styles.fileInput}>
            <div className={styles.fileInputTitle}>ПКБ:</div>
            {filePKB?.name ?? (
              <div className={styles.fileInputDescription}>
                Загрузить <span className={styles.bold}>.pdf</span> файл
              </div>
            )}
            <input
              accept="application/pdf"
              type="file"
              onChange={handleFileChangePKB}
            />
          </label>
          <div className={styles.footerBox}>
            {filePKB?.name ? (
              <div className={styles.buttonGroup}>
                <Button onClick={handleRunOCR}>Запустить OCR</Button>
              </div>
            ) : (
              <div className={styles.disabled}>
                <Button onClick={handleRunOCR}>Запустить OCR</Button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.result}>
        {ocrPending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        <ResultBox />
      </div>
    </div>
  );
};

export const OCRPdf = ({
  contextApplication,
}: {
  contextApplication: global.contextApplication;
}) => {
  if (!contextApplication.data) return <></>;

  const { first_name, last_name, email } =
    contextApplication.data.created_by ?? {};
  // const {
  //   mean,
  //   id: kaspiId,
  //   created_at: kaspiCreatedAt,
  //   updated_at: kaspiUpdatedAt,
  //   starting_date: kaspiStartingDate,
  //   end_date: kaspiEndDate,
  //   is_approved: kaspiApproved,
  //   ...kaspi
  // } = contextApplication.data.ocr_log ?? {};
  const { date_made,created_at: pkbCreatedAt, ...pkb } = contextApplication.data.ocr_log ?? {};

  const pkbApproved = contextApplication.data?.ocr_log?.is_approved;

  return (
    <>
      {contextApplication.data.ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Результат OCR</Text>
          </View>
          <View style={pdfStyles.text}>
            <Text>
              OCR проведён экспертом: {[first_name, last_name, email].join(" ")}
            </Text>
            <Text>
            Дата проведения OCR: {new Date(contextApplication.data.created_at).toLocaleString()}
            </Text>
          </View>
          <View style={pdfStyles.heading2}>
            <Text>ПКБ</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.table}>
              {/* 1. Ф.И.О клиента */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"ID клиента"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.user_id}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Количество активных кредитов"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.ocr_log?.active_loans}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Дата получения сведений кредитным бюро"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {new Date(
                      contextApplication.data.ocr_log.date_made
                    ).toLocaleDateString()}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Клиент признан банкротом"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.ocr_log?.bankrupt ? "Есть" : "Нет"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"КДН"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.ocr_log?.kdn}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"КПД"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.ocr_log?.kpd}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Наличие доступа к своему кредитному отчёту"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.ocr_log?.no_access
                      ? "Есть"
                      : "Нет"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Непогашенная/оставшаяся сумма"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.ocr_log?.outstanding_amount}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Просроченные платежи за последние 60 дней"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.ocr_log?.overdue_60_days
                      ? "Есть"
                      : "Нет"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Просроченная сумма"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.ocr_log?.overdue_amount}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>{"Вовлечения в игорный бизнес"}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.ocr_log?.player ? "Есть" : "Нет"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      "Количество произведенных участниками ПКБ запросов за последние 30 дней"
                    }
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.ocr_log?.requests_30_days}
                  </Text>
                </View>
              </View>
            </View>
            {pkbApproved !== null && (
              <View style={pdfStyles.bottom}>
                <Text style={pdfStyles.result}>
                  Результат ПКБ: {pkbApproved ? "Одобрено" : "Отказано"}
                </Text>
              </View>
            )}
          </View>
        </Page>
      )}
    </>
  );
};

export default OCR;
