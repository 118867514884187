import isDev from "./is-dev";

const isTestDeploy = process.env.REACT_APP_TEST_DEPLOY === "1";

const devURL = "http://5.35.107.17/api/dev";

const prodURL = "http://5.35.107.17/api";

const baseURL = isDev || isTestDeploy ? devURL : prodURL;

export default baseURL;
