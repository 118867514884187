import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import Icon from "icons";
import navbar from "./navbar";
import styles from "./navbar.module.scss";
import { useEffect, useState } from "react";

const Navbar = ({ resultBlocked }: { resultBlocked: boolean }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(null);

  const navlist: navbar.navItem[] = [
    {
      to: id + "/ocr/pkb",
      icon: "receiptSearch",
      iconActive: "receiptSearchFilled",
      text: "OCR",
      disabled: false,
    },
    // {
    //   to: id + "/scoring",
    //   icon: "documentFilter",
    //   iconActive: "documentFilterFilled",
    //   text: "Скоринг",
    //   disabled: false,
    // },
    {
      to: id + "/result",
      icon: "verify",
      iconActive: "verifyFill",
      text: "Результат",
      disabled: resultBlocked,
    },
  ];

  const handleNavBack = () => {
    if (page) {
      navigate(`/?page=${page}`);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (location?.state?.key) {
      setPage(location?.state?.key);
    }
  }, [location?.state?.key]);

  return (
    <div className={styles.navbar}>
      <div className={styles.list}>
        {navlist.map((item) => (
          <NavLink
            draggable={false}
            to={item.to}
            className={({ isActive }) =>
              classNames(
                styles.linkLabel,
                isActive && styles.active,
                item.disabled && styles.disabled
              )
            }
            onClick={(event) => {
              if (item.disabled) event.preventDefault();
            }}
            key={item.icon}
          >
            {({ isActive }) => (
              <div className={styles.link}>
                <Icon
                  className={styles.icon}
                  name={isActive ? item.iconActive : item.icon}
                />
                {item.text}
              </div>
            )}
          </NavLink>
        ))}
        <button
          type="button"
          className={styles.linkLabel}
          onClick={handleNavBack}
        >
          <div className={styles.link}>
            <Icon className={styles.icon} name="longArrowLeft" />
            Назад
          </div>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
