import React from "react";
import classNames from "classnames";
import * as math from "mathjs";

import Input from "components/input";
import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Icon from "icons";
import request from "request";
import styles from "./action-history.module.scss";
import actionHistory from "./action-history";

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

const ActionHistory = () => {
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [pending, setPending] = React.useState(true);
  const resultRef = React.useRef<HTMLDivElement | null>(null);

  const [data, setData] = React.useState<actionHistory.result[]>([]);

  const handleFilter = async () => {
    const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
    setPending(true);
    setPage(1);
    resultRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    request
      .get(`/users/actions?fd=${fd}&td=${td}&page=${page}`)
      .then((res) => res.data)
      .then((d) => {
        setData(d.records);
        setRowCount(d.total_count);
      })
      .finally(() => setPending(false));
  };

  const handleClear = () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    handleFilter();
  };

  React.useEffect(() => {
    const handleLoadRows = async () => {
      const fd = from
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
      setPending(true);
      resultRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      request
        .get(`/users/actions?fd=${fd}&td=${td}&page=${page}`)
        .then((res) => res.data)
        .then((d) => {
          setData(d.records);
          setRowCount(d.total_count);
        })
        .finally(() => setPending(false));
    };
    handleLoadRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.title}>История событий</div>
        <div className={styles.tools}>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input
                title="От"
                value={from}
                type="date"
                onSetDate={(d) => setFrom(d)}
              />
            </div>
            <div className={styles.input}>
              <Input
                title="До"
                value={to}
                type="date"
                onSetDate={(d) => setTo(d)}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button pending={pending} onClick={handleFilter}>
                Фильтр
              </Button>
              <Button pending={pending} color="red" onClick={handleClear}>
                Сбросить
              </Button>
            </div>
          </div>
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data.map((item) => (
              <div className={styles.tableRowWrap} key={item.id}>
                <div className={styles.tableRow}>
                  <div className={styles.item}>
                    <span>Время</span>{" "}
                    {new Date(item.date).toLocaleString()}
                  </div>
                  <div className={styles.item}>
                    <span>Эл.почта</span>{" "}
                    {item.email}
                  </div>
                  <div className={styles.item}>
                    <span>Дейстивие</span>
                    {item.action}
                  </div>
                  <div className={styles.item}>
                    <span>Описание</span>
                    {String(item.description ?? "–")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!pending && data.length === 0 && (
          <div className={styles.tableIsEmptyInfo}>Нет событий</div>
        )}
        {rowCount > 15 && (
          <div className={styles.pagination}>
            <button
              className={classNames(styles.arrow, page <= 1 && styles.disabled)}
              onClick={() => setPage(math.max(page - 1, 1))}
            >
              <Icon name="arrowLeft" />
            </button>
            <div className={styles.label}>
              {page} / {math.round(rowCount / 15)}
            </div>
            <button
              className={classNames(
                styles.arrow,
                page * 15 >= rowCount && styles.disabled
              )}
              onClick={() =>
                setPage(math.min(page + 1, math.round(rowCount / 15)))
              }
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionHistory;
