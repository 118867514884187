import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

import request from "request";
import Input from "components/input";
import ButtonLoader from "components/button-loader";
import ToggleBox from "components/toggle-box";
import styles from "./login.module.scss";
import ReactCodeInput from "react-code-input";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [code, setCode] = React.useState("");

  const [isPendingLogin, setIsPendingLogin] = React.useState(false);
  const [isPendingCode, setIsPendingCode] = React.useState(false);
  const [waitingCode, setWaitingCode] = React.useState(false);
  const [secondsBeforeNewCodeSent, setSecondsBeforeNewCodeSent] =
    React.useState(0);

  const navigate = useNavigate();

  const correctFields = () => {
    const regexCheckEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.trim() === "") {
      toast.error("Пожалуйста заполните эл.почту");
      return false;
    }
    if (!email.toLowerCase().match(regexCheckEmail)) {
      toast.error("Неправильно введена эл.почта");
      return false;
    }
    if (password.trim() === "") {
      toast.error("Пожалуйста введите пароль");
      return false;
    }
    if (password.trim().length < 6) {
      toast.error("Минимальная длина пароля 6 символов");
      return false;
    }
    return true;
  };

  const handlLogin = (event: React.FormEvent) => {
    event.preventDefault();
    if (isPendingLogin || secondsBeforeNewCodeSent > 0 || !correctFields())
      return;

    setIsPendingLogin(true);

    request
      .post("/login", {
        email: email.toLowerCase(),
        password: password,
      })
      .then((res) => res.data)
      .then((res) => {
        if (!res.is_sent) {
          if (res.token) {
            localStorage.setItem("token", res.token);
            navigate("/");
          } else {
            toast.success(
              "Код не был отправлен. Пожалуйста, попробуйте еще раз."
            );
          }
        } else {
          setWaitingCode(true);
          setSecondsBeforeNewCodeSent(60);
          toast.success("Код успешно отправлен на вашу почту.");
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 403) toast.error("Неправильный пароль!");
        if (err.response?.status === 404)
          toast.error("Пользователь не найден!");
        else if (err.response?.status === 429)
          toast.error("Подождите перед тем как отправить заново новый код!");
        else if (err.response?.status === 406)
          toast.error("Неправильный адрес электронной почты или пароль!");
      })
      .finally(() => {
        setIsPendingLogin(false);
      });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
    else localStorage.clear();
  }, [navigate]);

  return (
    <div
      className={classNames(
        styles.email,
        (isPendingLogin || isPendingCode) && styles.pending
      )}
    >
      <div className={styles.container}>
        {!waitingCode && (
          <form className={styles.pan} onSubmit={handlLogin}>
            <div className={styles.inputs}>
              <Input
                title="Email"
                type="string"
                value={email}
                onInput={(value) => setEmail(value)}
              />
              <Input
                title="Password"
                type="password"
                value={password}
                onInput={(value) => setPassword(value)}
              />
            </div>
            <button className={styles.submit}>
              {isPendingLogin && (
                <span className={styles.loading}>
                  <ButtonLoader />
                </span>
              )}
              {!isPendingLogin && (
                <span className={styles.submitText}>Login</span>
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
