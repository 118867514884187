import React from "react";
import { CSSTransition } from "react-transition-group";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Icon from "icons";
import classNames from "classnames";
import { useSelector } from "store/hooks";
import { ResultPdf } from "pages/result";
import Button from "components/button";
import prettierNumber from "utils/prettier-number";
import styles from "./result-box.module.scss";
import modalStyles from "./modals/recommendation.module.scss";
import Modal from "./modals/recommendation";

const ResultBox = () => {
  const contextApplication = useSelector((state) => state.application);

  const [recommendationModal, setRecommendationModal] = React.useState(false);

  // const handleOpenRecommendation = () => setRecommendationModal(true);

  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  return (
    <div className={styles.resultList}>
      {contextApplication.data?.selected_recommendation && (
        <div className={styles.recommendationBox}>
          <div className={styles.recommendationBox_title}>
            Выбранная рекомендация
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Сумма</div>
            <div className={styles.key}>
              {prettierNumber(selectedRecommendation?.amount ?? 0)} ₸
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Длительность</div>
            <div className={styles.key}>
              {selectedRecommendation?.duration} мес.
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Вероятность выплаты</div>
            <div className={styles.key}>
              {Number(selectedRecommendation?.good_prob) * 100} %
            </div>
          </div>
        </div>
      )}
      <div className={styles.resultBox}>
        <div className={styles.resultBox_title}>Статус заявки</div>
        {contextApplication.data?.ocr_log !== null && (
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={
                contextApplication.data?.ocr_log?.is_approved
                  ? "tickCircle"
                  : "closeFilled"
              }
              className={classNames(
                contextApplication.data?.ocr_log?.is_approved
                  ? styles.green
                  : styles.red
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                contextApplication.data?.ocr_log?.is_approved
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.selected_recommendation
                ? "Одобрено по рекомендации"
                : contextApplication.data?.ocr_log.is_approved
                ? "Одобрено"
                : "Отказано"}
            </div>
          </div>
        )}
        <div className={styles.resultBox_resultText}>
          {/* <div className={styles.item}>
            <div className={styles.key}>КАСПИ:</div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.ocr_log === null ||
                  contextApplication.data?.ocr_log?.ocr_log === null
                  ? styles.grey
                  : contextApplication.data?.ocr_log?.ocr_log
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.ocr_log === null ||
              contextApplication.data?.ocr_log?.ocr_log === null
                ? "Неизвестно"
                : contextApplication.data?.ocr_log?.ocr_log
                ? "Одобрено"
                : "Отказано"}
            </div>
          </div> */}
          <div className={styles.item}>
            <div className={styles.key}>ПКБ:</div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.ocr_log === null
                  ? styles.grey
                  : contextApplication.data?.ocr_log.is_approved
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.ocr_log === null
                ? "Неизвестно"
                : contextApplication.data?.ocr_log?.is_approved
                ? "Одобрено"
                : "Отказано"}
            </div>
          </div>
          {/* <div className={styles.item}>
            <div className={styles.key}>КДН:</div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.cdn_log === null
                  ? styles.grey
                  : contextApplication.data?.cdn_log?.ocr_log
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.cdn_log === null
                ? "Неизвестно"
                : contextApplication.data?.cdn_log?.ocr_log
                ? "Одобрено"
                : "Отказано"}
            </div>
          </div> */}
          {/* <div className={styles.item}>
            <div className={styles.key}>
              СКОРИНГ{" "}
              {contextApplication.data?.scoring_log &&
                `(${Math.round(contextApplication.data?.scoring_log.prediction * 100)}%)`}
              :
            </div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.scoring_log === null
                  ? styles.grey
                  : contextApplication.data?.scoring_log?.ocr_log
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.scoring_log === null
                ? "Неизвестно"
                : contextApplication.data?.scoring_log?.ocr_log
                ? "Одобрено"
                : "Отказано"}
            </div>
          </div> */}
        </div>
        {contextApplication.data?.ocr_log !== null && (
          <PDFDownloadLink
            fileName={"кредитная-заявка-" + contextApplication.data?.id}
            document={<ResultPdf contextApplication={contextApplication} />}
          >
            <Button color="blue">Скачать результат</Button>
          </PDFDownloadLink>
        )}
        {/* {contextApplication?.data &&
        !contextApplication?.data?.selected_recommendation &&
        contextApplication?.data.recommendations_log?.length > 0 ? (
          <Button color="violet" onClick={handleOpenRecommendation}>
            Рекомендации к заявке
          </Button>
        ) : contextApplication.data?.ocr_log === false ? (
          <div className={styles.non_rec_text}>Рекомендаций нет</div>
        ) : (
          <></>
        )} */}
      </div>
      <CSSTransition
        in={!!recommendationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal
          application={contextApplication.data}
          onClose={() => setRecommendationModal(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default ResultBox;
