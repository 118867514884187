import React from "react";
import global from "global";

import prettierNumber from "utils/prettier-number";
import { useDispatch } from "store/hooks";
import Button from "components/button";
import request from "request";
import transcript from "utils/transcript";
import styles from "./recommendation.module.scss";

const Modal = ({
  onClose,
  recommendateAmount,
  application,
}: {
  onClose: () => void;
  recommendateAmount: number;
  application: global.application | null;
}) => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(false);

  const handleSelectLTVRecommendation = () => {
    setPending(true);
    request
      .put(`/applications/${application?.id}/amount`, {
        amount: recommendateAmount,
      })
      .then(() => {
        dispatch.application.FETCH_APPLICATION_BY_ID(application?.id);
      })
      .finally(() => {
        setPending(false);
        onClose();
      });
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <div
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>
          ЛТВ по {transcript.collateral(application?.collateral!)} превышает
        </div>
        <div className={styles.recommendation}>
          <div className={styles.item}>
            <div className={styles.key}>Доступная сумма</div>
            <div className={styles.value}>
              {prettierNumber(recommendateAmount)} ₸
            </div>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button pending={pending} onClick={handleSelectLTVRecommendation}>
            Подтвердить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
