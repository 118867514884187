import React from "react";
import { AxiosError } from "axios";
import { useAlert } from "react-alert";

import Button from "components/button";
import RangeBox from "components/range-box";
import request from "request";
import styles from "./settings.module.scss";
import Input from "components/input";

const Settings = () => {
  const alert = useAlert();
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);
  const [settings, setSettings] = React.useState({
    food_inflation: "",
    nonfood_inflation: "",
    services_inflation: "",
    exrate_usd_avg: "",
    avrate: "",
    gdp: "",
    subsistence_level: "",
    brent_oil_price: "",
    threshold_with_collateral: 0,
    threshold_without_collateral: 0,
  });

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        food_inflation: Number(settings.food_inflation),
        nonfood_inflation: Number(settings.nonfood_inflation),
        services_inflation: Number(settings.services_inflation),
        exrate_usd_avg: Number(settings.exrate_usd_avg),
        avrate: Number(settings.avrate),
        gdp: Number(settings.gdp),
        brent_oil_price: Number(settings.brent_oil_price),
        subsistence_level: Number(settings.subsistence_level),
        threshold_with_collateral: settings.threshold_with_collateral / 100,
        threshold_without_collateral:
          settings.threshold_without_collateral / 100,
      })
      .then(() => alert.success("Сохранено!"))
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setSettings({
          ...data,
          threshold_with_collateral: Number(
            (data.threshold_with_collateral * 100).toFixed()
          ),
          threshold_without_collateral: Number(
            (data.threshold_without_collateral * 100).toFixed()
          ),
        });
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Настройки</div>
          <div className={styles.fieldList}>
            <Input
              title="Инфляция прод.товаров"
              value={settings.food_inflation}
              step={0.1}
              type="float"
              onInput={(value) =>
                setSettings({ ...settings, food_inflation: value })
              }
            />
            <Input
              title="Инфляция непрод.товаров"
              value={settings.nonfood_inflation}
              step={0.1}
              type="float"
              onInput={(value) =>
                setSettings({ ...settings, nonfood_inflation: value })
              }
            />
            <Input
              title="Инфляция услуг"
              value={settings.services_inflation}
              step={0.1}
              type="float"
              onInput={(value) =>
                setSettings({ ...settings, services_inflation: value })
              }
            />
            <Input
              title="Курс доллара"
              value={settings.exrate_usd_avg}
              step={0.1}
              type="float"
              onInput={(value) =>
                setSettings({ ...settings, exrate_usd_avg: value })
              }
            />
            <Input
              title="Средний доход населения"
              value={settings.avrate}
              step={0.1}
              type="float"
              onInput={(value) => setSettings({ ...settings, avrate: value })}
            />
            <Input
              title="ВВП"
              value={settings.gdp}
              step={0.1}
              type="float"
              onInput={(value) => setSettings({ ...settings, gdp: value })}
            />
            <Input
              title="Цена на нефть Brent"
              value={settings.brent_oil_price}
              step={0.1}
              type="float"
              onInput={(value) =>
                setSettings({ ...settings, brent_oil_price: value })
              }
            />
            <Input
              title="ВПМ"
              value={settings.subsistence_level}
              step={1}
              type="integer"
              onInput={(value) =>
                setSettings({ ...settings, subsistence_level: value })
              }
            />
          </div>
          <div className={styles.fieldTitle}>
            Порог для залоговых продуктов
          </div>
          <RangeBox
            value={settings.threshold_with_collateral}
            setValue={(value) =>
              setSettings({ ...settings, threshold_with_collateral: value })
            }
          />
          <div className={styles.fieldTitle}>
            Порог для незалоговых продуктов
          </div>
          <RangeBox
            value={settings.threshold_without_collateral}
            setValue={(value) =>
              setSettings({ ...settings, threshold_without_collateral: value })
            }
          />
        </div>
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
