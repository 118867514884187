import { AxiosError } from "axios";
import Button from "components/button";
import React from "react";
import { toast } from "react-toastify";
import request from "request";
import styles from "./read-passport.module.scss";

interface IResult {
  front: {
    [key: string]: string;
  };
  tech: {
    [key: string]: string;
  };
}

const ReadPassport = () => {
  const [passport, setPassport] = React.useState<File | null>();
  const [techPassport, setTechPassport] = React.useState<File | null>();

  const [result, setResult] = React.useState<IResult>();
  const [pending, setPending] = React.useState(false);

  const handleRunOCR = async () => {
    setPending(true);
    const fd = new FormData();
    if (passport) fd.append("passport_front", passport);
    if (techPassport) fd.append("passport_tech", techPassport);
    request({
      url: `/applications/ocr/passport`,
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => res.data)
      .then((res) => setResult(res))
      .catch((res: AxiosError) => {
        if (res.response?.status === 422)
          toast.error("Пожалуйста загрузите пасспорт и тех.пассорт фотографии");
      })
      .finally(() => setPending(false));
  };

  const handleFileChangePassport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setPassport(event.target.files[0]);
  };

  const handleFileChangeTechPassport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setTechPassport(event.target.files[0]);
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.inputBox}>
          <label className={styles.fileInput}>
            <div className={styles.fileInputTitle}>Пасспорт:</div>
            {passport?.name ?? (
              <div className={styles.fileInputDescription}>Загрузить файл (.png .jpg)</div>
            )}
            <input accept="image/png, image/jpeg" type="file" onChange={handleFileChangePassport} />
          </label>
          <label className={styles.fileInput}>
            <div className={styles.fileInputTitle}>Тех.пасспорт:</div>
            {techPassport?.name ?? (
              <div className={styles.fileInputDescription}>Загрузить файл (.png .jpg)</div>
            )}
            <input
              accept="image/png, image/jpeg"
              type="file"
              onChange={handleFileChangeTechPassport}
            />
          </label>
          <div className={styles.footerBox}>
            <div className={styles.buttonGroup}>
              <Button pending={pending} onClick={handleRunOCR}>
                Запустить OCR
              </Button>
            </div>
          </div>
        </div>
        {!pending && result && (
          <div className={styles.result}>
            <div className={styles.resultSide}>
              <div className={styles.resultTitle}>Данные паспорта</div>
              {Object.entries(result.front).map(([key, value]) => (
                <div className={styles.resultItem} key={key}>
                  <div className={styles.resultKey}>{key}</div>
                  <div className={styles.resultKey}>{value}</div>
                </div>
              ))}
            </div>
            <div className={styles.resultSide}>
              <div className={styles.resultTitle}>Данные тех.паспорта</div>
              {Object.entries(result.tech).map(([key, value]) => (
                <div className={styles.resultItem} key={key}>
                  <div className={styles.resultKey}>{key}</div>
                  <div className={styles.resultKey}>{value}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadPassport;
